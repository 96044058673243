import { getPostHogKey } from "./../utils/postHog"

const options = {
  version: process.env.REACT_APP_VERSION,
  dashboard: {
    width: 1190,
    maxUserDashboards: 4,
    maxWidgetsPerDashboard: 16
  },
  pageBuilder: {
    pageWidth: 1280,
    pageHeight: 720,
    columnCount: {
      landscape: 80,
      portrait: 45
    },
    rowCount: {
      landscape: 45,
      portrait: 80
    },
    marginCount: 4
  },
  widget: {
    noDataMessage: 'There is a no data available.',
    subscriptionInquiryList: [{
      email: 'busdev@q4inc.com',
      type: 'to'
    }]
  },
  s3StaticFiles: 'https://q4-touch.s3.amazonaws.com',
  agGridKey: 'Using_this_{AG_Grid}_Enterprise_key_{AG-052668}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Q4,_Inc.}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Q4_Desktop}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Q4_Desktop}_need_to_be_licensed___{Q4_Desktop}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_March_2025}____[v3]_[01]_MTc0MDc4NzIwMDAwMA==2ae80001c6f2f1c5e9906a491e299a22',
  e2e_test_user: {
    legacy: {
      email: process.env.REACT_APP_E2E_USEREMAIL,
      password: process.env.REACT_APP_E2E_PASSWORD
    },
    // To be used for with Nightwatch E2E tests, have to be created in Auth0 first and verified that Nightwatch can login
    sso: {
      email: '',
      password: ''
    }
  },
  pusherToken: process.env.REACT_APP_PUSHER_TOKEN,
  googleAnalytics: process.env.REACT_APP_GOOGLE_ANALYTICS,
  desktopUrl: process.env.REACT_APP_DESKTOP_STATIC_URL,
  capitalConnectUrl: process.env.REACT_APP_CAPITAL_CONNECT_URL,
  emailMfeUrl: process.env.REACT_APP_EMAIL_MFE_URL,
  platformAuth0ClientId: process.env.REACT_APP_PLATFORM_AUTH0_CLIENT_ID,
  platformAuth0Audience: process.env.REACT_APP_PLATFORM_AUTH0_AUDIENCE,
  platformQ4IdpClientId: process.env.REACT_APP_PLATFORM_Q4IDP_CLIENT_ID,
  platformQ4IdpAudience: process.env.REACT_APP_PLATFORM_Q4IDP_AUDIENCE,
  platformQ4IdpResource: process.env.REACT_APP_PLATFORM_Q4IDP_RESOURCE,
  apiUrl: `${process.env.REACT_APP_DESKTOP_API_URL}/api`,
  exportApiUrl: `${process.env.REACT_APP_DESKTOP_EXPORT_API_URL}/api`,
  graphqlUrl: process.env.REACT_APP_DESKTOP_GRAPHQL_URL,
  baseUrl: process.env.REACT_APP_DESKTOP_URL,
  loggerEmail: process.env.REACT_APP_DESKTOP_LOGGER_EMAIL,
  helpUrl: process.env.REACT_APP_DESKTOP_HELP_URL,
  featureFlag: {
    apiKey: process.env.REACT_APP_LAUNCHDARKLY_API_KEY
  },
  auth0ResetEmailUrl: process.env.REACT_APP_AUTH0_EMAIL_URL,
  auth0ResetEmailDb: process.env.REACT_APP_AUTH0_DB,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  auth0Scope: process.env.REACT_APP_AUTH0_SCOPE,
  analytics: {
    postHog: {
      key: getPostHogKey(),
      apiHost: "https://app.posthog.com",
    },
  },
  idp: {
    audience: process.env.REACT_APP_Q4_DESKTOP_IDP_AUDIENCE,
    clientId: process.env.REACT_APP_Q4_DESKTOP_IDP_CLIENT_ID,
    baseUrl: process.env.REACT_APP_Q4_DESKTOP_IDP_BASE_URL,
    resource: process.env.REACT_APP_Q4_DESKTOP_IDP_RESOURCE,
  },
}

export const config = {
  ...options
}

export const LDConfig = {
  clientSideID: config.featureFlag.apiKey,
  options: {
    /**
     * Whether the client should make a request to LaunchDarkly for A/B testing goals.
     * This is true by default, meaning that this request will be made on every page load.
     * Set it to false if you are not using A/B testing and want to skip the request.
     */
    fetchGoals: false
  },
  reactOptions: {
    /**
     * Whether the React SDK should transform flag keys into camel-cased format. Using camel-cased flag keys allow for
     * easier use as prop values, however, these keys won't directly match the flag keys as known to LaunchDarkly.
     * Consequently, flag key collisions may be possible and the Code References feature will not function properly.
     */
    useCamelCaseFlagKeys: false
  }
}
