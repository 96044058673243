import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withAuth } from 'react-oidc-context';

import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { userLogout } from '../../actions'
import { oAuthHelper, LOCAL_AUTH_MODE, USED_LOCAL_AUTH_MODE } from '../../services/oAuth.service'
import { isLocalhostApi, removeLocalizationLocalStorage, getLocalStorageItem } from '../../utils'
import { ACCESS_TOKEN, ACCESS_TOKEN_OAUTH } from '../../services/legacyAuth.service'

class LogoutContainer extends Component {
  UNSAFE_componentWillMount () {
    removeLocalizationLocalStorage()

    // Remove Cookies
    const domain = !isLocalhostApi() ? 'domain=.q4desktop.com;' : ''
    document.cookie = `${ACCESS_TOKEN}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domain}`

    // Clear local authentication mode
    const usedLocalAuthMode = !!localStorage.getItem(USED_LOCAL_AUTH_MODE.KEY)
    const localAuthMode = getLocalStorageItem(LOCAL_AUTH_MODE.KEY)    

    if (usedLocalAuthMode) {
      /* Workaround for silent redirect during Bulk Email with Q4IDP (Desktop) Auth FF off.
       * When disabled, the user is redirected to /email in Auth0 mode, which clears the 
       * local auth state and switches back to Auth0.
       */
      const isBulkEmailIdpAuthCallback = localAuthMode === LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP && window.self !== window.top;
      if (!isBulkEmailIdpAuthCallback) {
        localStorage.removeItem(ACCESS_TOKEN_OAUTH)
        localStorage.removeItem(LOCAL_AUTH_MODE.KEY)
        localStorage.removeItem(USED_LOCAL_AUTH_MODE.KEY)
      }
    }

    // Clear auth
    if (oAuthHelper.useOAuthSDK()) {
      this.props.auth.removeUser()
      this.props.auth.signoutRedirect()
    } else {
      this.props.auth?.logout()

      // Clear user state
      this.props.userLogout()
    }
  }

  render () {
    // Remove this temp approach after full deprecation of Legacy Authentication
    // Currently required to force LaunchDarklyWrapper to 'reset' when using Legacy Login
    return (
      <>
        {
          oAuthHelper.useOAuthSDK()
            ? <></> // oAuth redirect is managed by auth.removeUser()
            : <>{window.location.href = '/login'}</>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = (dispatch) => ({
  userLogout: bindActionCreators(userLogout, dispatch)
})

LogoutContainer.propTypes = {
  auth: PropTypes.object
}

export const LogoutContainerSelfAuth = withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutContainer))
export const LogoutContainerOAuth = withRouter(connect(mapStateToProps, mapDispatchToProps)(withAuth(LogoutContainer)))
