import React from 'react'
import { AuthProvider } from 'react-oidc-context'
import { getOAuthConfig } from '../../hook/useOAuthConfig.hook'
import { getCurrentAuthMode } from '../../utils/auth/auth.util'
import { LOCAL_AUTH_MODE } from '../../services/oAuth.service'

const CCOAuthProviderWrapper = ({ children }) => {
  const platformAuthMode = getCurrentAuthMode() === LOCAL_AUTH_MODE.VALUE.DESKTOP_Q4IDP
    ? LOCAL_AUTH_MODE.VALUE.PLATFORM_Q4IDP
    : LOCAL_AUTH_MODE.VALUE.PLATFORM_AUTH0

  const authConfig = getOAuthConfig(platformAuthMode);

  return (
    <AuthProvider {...authConfig}>
      {children}
    </AuthProvider>
  )
}

export default CCOAuthProviderWrapper
